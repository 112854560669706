﻿@import "Bootstrap/_functions.scss";
@import "Bootstrap/_variables.scss";

$light-blue: #98dafc;
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$light-green: #6fb380;
$teal: #20c997;
$cyan: #17a2b8;

$primary-white: #feffff;
$primary-blue: #98dafc;
$primary-beige: #daad86;
$primary-black: #312c32;
$primary-green: #57bc90;

//fonts
$font-lobster: "Lobster", cursive;
$font-lobster-two: "Lobster Two", cursive;
$font-lato: "Lato", sans-serif;

//navbar
$navbar-light-toggler-color: $primary-green;
// $navbar-light-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-toggler-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-light-toggler-border-color: transparent;
$navbar-light-color: $primary-black;
$navbar-light-hover-color: $primary-green;
$navbar-item-font: $font-lato;
$navbar-item-font-size: 1.5rem;
$navbar-light-active-color: $primary-green;

//footer
$footer-min-height: 150px;
$footer-top-background: $primary-black;
$footer-top-color: $primary-white;
$footer-bottom-background: $primary-green;
$footer-bottom-color: $primary-black;

$logo-margin-top-bottom: 0px;

$menu-item-color: #333;
$menu-item-color-hover: theme-color("primary");

$carousel-indicator-size: 1rem;
// $carousel-background: #9b9081;
$carousel-background: $primary-white;
$carousel-color: $cyan;
$carousel-image: url("../images/background.jpg");

$contact-color: #444;

$title-height: 80px;

$padding-sizes: 0 500 1000 1500 2000;
$padding-types: left right top bottom;

$margin-sizes: 0 500 1000 1500 2000;
$margin-types: left right top bottom;

$colors: (
  "blue": $blue,
  "light-blue": $light-blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "light-green": $light-green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
  "primary-white": $primary-white,
  "primary-blue": $primary-blue,
  "primary-beige": $primary-beige,
  "primary-black": $primary-black,
  "primary-green": $primary-green,
);
